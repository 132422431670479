$(document).ready(function () {
  // background-image: url("../Images/h5.webp");
  var random = Math.floor(Math.random() * 8) + 1;
  var imageURL = "url(" + "./Images/index" + random + ".webp" + ")";

  $("#mainDiv").css("background-image", imageURL);
  // $("#navigationBar").load("./navbar.html");
  var scrollPosition = $(window).height() + $(window).scrollTop();
  var curScrollPosition = scrollPosition;
  var prevScrollPosition = scrollPosition;
  AOS.init();
  var flag = true;
  $(document).scroll(function () {
    if ($("#svgTest").visible()) {
      $("#testVector1").css("animation-play-state", "running");
      $("#testVector2").css("animation-play-state", "running");
      $("#testVector3").css("animation-play-state", "running");
      $("#testVector4").css("animation-play-state", "running");
      $("#testVector5").css("animation-play-state", "running");
      $("#testVector6").css("animation-play-state", "running");
      $("#testVector7").css("animation-play-state", "running");
      $("#testVector8").css("animation-play-state", "running");
      $("#testVector9").css("animation-play-state", "running");
      $("#testVector10").css("animation-play-state", "running");
      $("#testVector11").css("animation-play-state", "running");
      $("#testVector12").css("animation-play-state", "running");
      $("#testVector13").css("animation-play-state", "running");
      $("#testVector14").css("animation-play-state", "running");
      $("#testVector15").css("animation-play-state", "running");
      $("#testVector16").css("animation-play-state", "running");
      $("#txtTest").addClass("animated fadeInLeftBig delay-2s");
      $("#line1").css("animation-play-state", "running");
      $("#line2").css("animation-play-state", "running");
      $("#svgTest").addClass("animated fadeIn delay-2s");
      $("#svgTest").css("opacity", "1");
    } else {
      $("#testVector1").css("animation-play-state", "paused");
      $("#testVector2").css("animation-play-state", "paused");
      $("#testVector3").css("animation-play-state", "paused");
      $("#testVector4").css("animation-play-state", "paused");
      $("#testVector5").css("animation-play-state", "paused");
      $("#testVector6").css("animation-play-state", "paused");
      $("#testVector7").css("animation-play-state", "paused");
      $("#testVector8").css("animation-play-state", "paused");
      $("#testVector9").css("animation-play-state", "paused");
      $("#testVector10").css("animation-play-state", "paused");
      $("#testVector11").css("animation-play-state", "paused");
      $("#testVector12").css("animation-play-state", "paused");
      $("#testVector13").css("animation-play-state", "paused");
      $("#testVector14").css("animation-play-state", "paused");
      $("#testVector15").css("animation-play-state", "paused");
      $("#testVector16").css("animation-play-state", "paused");
    }

    if ($("#svgNetwork").visible()) {
      $("#networkVectorr1").css("animation-play-state", "running");
      $("#networkVectorr2").css("animation-play-state", "running");
      $("#networkVectorr3").css("animation-play-state", "running");
      $("#networkVectorl1").css("animation-play-state", "running");
      $("#networkVectorl2").css("animation-play-state", "running");
      $("#networkVectorl3").css("animation-play-state", "running");
      $("#txtNetwork").addClass("animated fadeInRightBig delay-2s");
      $("#line3").css("animation-play-state", "running");
      $("#line4").css("animation-play-state", "running");
      $("#svgNetwork").addClass("animated fadeIn delay-2s");
      $("#svgNetwork").css("opacity", "1");
    } else {
      $("#networkVectorr1").css("animation-play-state", "paused");
      $("#networkVectorr2").css("animation-play-state", "paused");
      $("#networkVectorr3").css("animation-play-state", "paused");
      $("#networkVectorl1").css("animation-play-state", "paused");
      $("#networkVectorl2").css("animation-play-state", "paused");
      $("#networkVectorl3").css("animation-play-state", "paused");
    }

    if ($("#svgTelecom").visible()) {
      $("#telecomVector1").css("animation-play-state", "running");
      $("#telecomVector2").css("animation-play-state", "running");
      $("#txtTelecom").addClass("animated fadeInLeftBig delay-2s");
      $("#line5").css("animation-play-state", "running");
      $("#line6").css("animation-play-state", "running");
      $("#svgTelecom").addClass("animated fadeIn delay-2s");
      $("#svgTelecom").css("opacity", "1");
    } else {
      $("#telecomVector1").css("animation-play-state", "paused");
      $("#telecomVector2").css("animation-play-state", "paused");
    }

    if ($("#svgContract").visible()) {
      $("#contractVector1").css("animation-play-state", "running");
      $("#contractVector2").css("animation-play-state", "running");
      $("#contractVector3").css("animation-play-state", "running");
      $("#txtContract").addClass("animated fadeInRightBig delay-2s");
      $("#line7").css("animation-play-state", "running");
      $("#line8").css("animation-play-state", "running");
      $("#svgContract").addClass("animated fadeIn delay-2s");
      $("#svgContract").css("opacity", "1");
    } else {
      $("#contractVector1").css("animation-play-state", "paused");
      $("#contractVector2").css("animation-play-state", "paused");
      $("#contractVector3").css("animation-play-state", "paused");
    }

    if ($("#svgCable").visible()) {
      $("#line9").css("animation-play-state", "running");
      $("#txtCable").addClass("animated fadeInUpBig delay-2s");
      $("#svgCable").addClass("animated fadeIn delay-2s");
      $("#svgCable").css("opacity", "1");
      $("#cableVR1").css("animation-play-state", "running");
      $("#cableVR2").css("animation-play-state", "running");
      $("#cableVL1").css("animation-play-state", "running");
      $("#cableVL2").css("animation-play-state", "running");
      $("#cableV").css("animation-play-state", "running");
    } else {
      $("#cableVR1").css("animation-play-state", "paused");
      $("#cableVR2").css("animation-play-state", "paused");
      $("#cableVL1").css("animation-play-state", "paused");
      $("#cableVL2").css("animation-play-state", "paused");
      $("#cableV").css("animation-play-state", "paused");
    }

    if ($("#grpProvenHistory").visible()) {
      $("#elpProvenHistory").addClass("animated fadeIn");
      $("#txtProvenHistory").addClass("animated fadeIn delay-1s");
      $("#elpProvenHistory").css("opacity", "1");
      $("#txtProvenHistory").css("opacity", "1");
      $("#curve1").css("animation-play-state", "running");
    } else {
    }
    if ($("#grpPartnership").visible()) {
      $("#elpPartnership").addClass("animated fadeIn");
      $("#txtPartnership").addClass("animated fadeIn delay-1s");
      $("#elpPartnership").css("opacity", "1");
      $("#txtPartnership").css("opacity", "1");
      $("#curve2").css("animation-play-state", "running");
    } else {
    }
    if ($("#grpWorkForce").visible()) {
      $("#elpWorkForce").addClass("animated fadeIn");
      $("#txtWorkForce").addClass("animated fadeIn delay-1s");
      $("#elpWorkForce").css("opacity", "1");
      $("#txtWorkForce").css("opacity", "1");
      $("#curve3").css("animation-play-state", "running");
    } else {
    }
    if ($("#grpOffices").visible()) {
      $("#elpOffices").addClass("animated fadeIn");
      $("#txtOffices").addClass("animated fadeIn delay-1s");
      $("#elpOffices").css("opacity", "1");
      $("#txtOffices").css("opacity", "1");
      $("#curve4").css("animation-play-state", "running");
    } else {
    }
    if ($("#grpLogistics").visible()) {
      $("#elpLogistics").addClass("animated fadeIn delay-2s");
      $("#txtLogistics").addClass("animated fadeIn delay-3s");
      $("#elpLogistics").css("opacity", "1");
      $("#txtLogistics").css("opacity", "1");
    } else {
    }

    if ($("#divProjects").visible()) {
      if (flag) {
        $(".count").each(function () {
          $(this)
            .prop("Counter", 0)
            .animate(
              {
                Counter: $(this).text(),
              },
              {
                duration: 1000,
                easing: "swing",
                step: function (now) {
                  $(this).text(Math.ceil(now));
                },
              },
            );
        });
        flag = false;
      }
    }
  });

  //bar
});
